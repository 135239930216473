import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CompSetState {
  value: string[];
}

const initialState: CompSetState = { value: [""] };
const hotelCompset = createSlice({
  name: "compSet",
  initialState,
  reducers: {
    addCompSet: (state: CompSetState, action: PayloadAction<string[]>) => {
      state.value = action.payload;
    },
    clearCompSet: (state: CompSetState) => {
      state.value = [];
    },
  },
});

export default hotelCompset.reducer;
export const { addCompSet, clearCompSet } = hotelCompset.actions;
