import React, { useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Link, useNavigate } from "react-router-dom";
import { confirmResetPassword } from "aws-amplify/auth";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import revImage from "../../assets/revanista-logo.png";

const ForgetButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: "var(--button-color)",
  marginTop: "2px",
  marginBottom: "2px",
  "&:hover": {
    backgroundColor: "var(--button-hover)",
  },
}));

const theme = createTheme();

const ResetPassword = () => {
  const [codeSection, setCodeSection] = useState<boolean>(false);
  const [emails, setEmails] = useState<string>("");
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const [passError, setPassError] = useState<boolean>(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmails(event.target.value);
    const name = event.target.name;
    const value = event.target.value;
    switch (name) {
      case "email":
        if (!value) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }

        break;
      case "code":
        if (!value) {
          setCodeError(true);
        } else {
          setCodeError(false);
        }

        break;
      case "newPassword":
        if (!value) {
          setPassError(true);
        } else {
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    codeSection
      ? receivedCode(
          emails,
          data.get("code") as string,
          data.get("newPassword") as string,
        )
      : resetPassword(data.get("email") as string);
  };

  const resetPassword = async (username: string) => {
    await resetPassword(username)
      .then((data) => {
        console.log(data);
        setCodeSection(true);
      })
      .catch((err) => {
        if (
          err ==
          "UserNotFoundException: Username/client id combination not found."
        ) {
          toast.error("Username/client id combination not found", {
            theme: "colored",
          });
        }
      });
  };

  const receivedCode = async (
    username: string,
    code: string,
    new_password: string,
  ) => {
    await confirmResetPassword({
      username: username,
      confirmationCode: code,
      newPassword: new_password,
    })
      .then(() => {
        toast.success("Success");
        navigate("/signIn");
      })
      .catch((err) => {
        if (err == "AuthError: Confirmation code cannot be empty") {
          toast.error("Confirmation code cannot be empty", {
            theme: "colored",
          });
        }
        if (err == "AuthError: Password cannot be empty") {
          toast.error("Password cannot be empty", {
            theme: "colored",
          });
        }
        if (
          err ==
          "InvalidPasswordException: Password does not conform to policy: Password must have uppercase characters"
        ) {
          toast.error("Password must have uppercase characters", {
            theme: "colored",
          });
        }
        console.log(err);
      });
  };

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    switch (name) {
      case "email":
        if (!value) {
          setEmailError(true);
        } else {
          setEmailError(false);
        }

        break;
      case "code":
        if (!value) {
          setCodeError(true);
        } else {
          setCodeError(false);
        }

        break;
      case "newPassword":
        if (!value) {
          setPassError(true);
        } else {
          setPassError(false);
        }

        break;

      default:
        break;
    }
  };
  return (
    <>
      <ToastContainer />
      {codeSection ? (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <img src={revImage} alt="revanista" />
              </div>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Typography variant="h4">Resets your password</Typography>
                <br />
                {/* <h1>Reset your password</h1> */}
                <span>reset code has been sent to the provided email.</span>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="code"
                  label="Code"
                  name="code"
                  autoComplete="code"
                  autoFocus
                  type="number"
                  onChange={handleOnChange}
                  onBlur={(e) => handleOnBlur(e)}
                  error={codeError}
                  helperText={codeError ? "Please fill code field" : ""}
                />

                <br />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="newPassword"
                  label="New Password"
                  name="newPassword"
                  autoComplete="newPassword"
                  type="password"
                  autoFocus
                  onChange={handleOnChange}
                  onBlur={(e) => handleOnBlur(e)}
                  error={passError}
                  helperText={passError ? "Please fill new password field" : ""}
                />

                <ForgetButton variant="contained" type="submit" fullWidth>
                  Reset password
                </ForgetButton>
                <br />
                <Grid container>
                  <Grid item xs>
                    <Link to="/signIn">
                      <span className="backLink"> Back to Sign In</span>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <p>
                      If you encounter any issue, reach out to{" "}
                      <a href="mailto:support@revanista.com?subject=Revanista Reset Password">
                        support@revanista.com
                      </a>
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div>
                <img src={revImage} alt="revanista" />
              </div>

              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                <Typography variant="h4">Reset your password</Typography>
                <br />
                {/* <h1>Reset your password</h1> */}
                <span>
                  As part of a system upgrade on Revanista, we need to verify
                  your email address and ask you to create a new password.
                </span>
                <p>
                  Enter your email address below to receive a code via email.
                </p>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={handleOnChange}
                  autoFocus
                  onBlur={(e) => handleOnBlur(e)}
                  error={emailError}
                  helperText={emailError ? "Please fill email field" : ""}
                />

                <br />

                <ForgetButton variant="contained" type="submit" fullWidth>
                  Send Code
                </ForgetButton>
                <br />
                <Grid container>
                  <Grid item xs>
                    <Link to="/signIn">
                      <span className="backLink"> Back to Sign In</span>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs>
                    <p>
                      If you encounter any issue, reach out to{" "}
                      <a href="mailto:support@revanista.com?subject=Revanista Reset Password">
                        support@revanista.com
                      </a>
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
    </>
  );
};

export default ResetPassword;
