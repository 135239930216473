import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface CounterState {
  value: number;
}

const initialState = { value: 9 } as CounterState;
const hotelSlice = createSlice({
  name: "hotelDataId",
  initialState,
  reducers: {
    addHotelId: (state: CounterState, action: PayloadAction<number>) => {
      state.value = action.payload;
    },
  },
});

export default hotelSlice.reducer;
export const { addHotelId } = hotelSlice.actions;
