import React, { useState, useEffect } from "react";
import MainMenu from "../Menu/Menu";
import YieldTable from "../Yield-Table/yieldTable";
import CircularProgress from "@mui/material/CircularProgress";

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Set initial loading state to false after a short delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <MainMenu />
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <YieldTable />
      )}
    </div>
  );
};

export default Dashboard;
