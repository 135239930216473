import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CognitoUser } from "amazon-cognito-identity-js";

export interface UserState {
  value?: CognitoUser;
}

const userSlice = createSlice({
  name: "userData",
  initialState: {},
  reducers: {
    addUser: (state: UserState, action: PayloadAction<CognitoUser>) => {
      state.value = action.payload;
    },
  },
});

export default userSlice.reducer;
export const { addUser } = userSlice.actions;
