import React, { useEffect, useState } from "react";
import Dashboard from "./Components/dashboard/Dashboard";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, Route, redirect } from "react-router-dom";
import SignIn from "./Auth/SignIn/SignIn";
import ForgetPassword from "./Auth/Forget-Password/ForgetPassword";
import { getCurrentUser } from "aws-amplify/auth";
import ProtectRoute from "./Authenticate/ProtectRoute";
import RequestNewPassword from "./Auth/RequestNewPassword/RequestNewPassword";
import ResetPassword from "./Auth/ResetPassword/ResetPassword";
import PageNotFound from "./Pages/404Page/PageNotFound";
import { AmplifyConfig } from "./amplify/config";

export default function App() {
  AmplifyConfig.configure();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInvalidUrl, setIsInvalidUrl] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const checkAuthState = async () => {
      try {
        await getCurrentUser();
        setIsAuthenticated(true);
        if (
          location.pathname === "/signIn" ||
          location.pathname === "/forgetPassword" ||
          location.pathname === "/requestNewPassword" ||
          location.pathname === "/resetPassword"
        ) {
          navigate("/");
        }
      } catch (error) {
        setIsAuthenticated(false);
        if (
          location.pathname !== "/signIn" &&
          location.pathname !== "/forgetPassword" &&
          location.pathname !== "/requestNewPassword" &&
          location.pathname !== "/resetPassword"
        ) {
          navigate("/signIn");
        }
      }
    };
    checkAuthState();

    if (location.hash.length !== 0) {
      setIsInvalidUrl(true);
    }

    redirect("/signIn");
  });

  const invalidURLState = () => {
    setIsInvalidUrl(false);
  };

  return (
    <>
      {isInvalidUrl == true ? (
        <>
          <PageNotFound invalidUrlState={invalidURLState} />
        </>
      ) : (
        <Routes>
          <Route path="/" element={<ProtectRoute Component={Dashboard} />} />
          {!isAuthenticated && (
            <>
              <Route path="/signIn" element={<SignIn />} />
              <Route path="/forgetPassword" element={<ForgetPassword />} />
              <Route
                path="/requestNewPassword"
                element={<RequestNewPassword />}
              />
              <Route path="/resetPassword" element={<ResetPassword />} />
            </>
          )}
          <Route
            path="*"
            element={<PageNotFound invalidUrlState={invalidURLState} />}
          />
        </Routes>
      )}
    </>
  );
}
