import React from "react";
import { HotTable } from "@handsontable/react";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "./yieldTable.css";
import { useEffect, useState } from "react";
import Pagination from "@mui/material/Pagination";
import { ToastContainer, toast } from "react-toastify";
import CircularProgress from "@mui/material/CircularProgress";
import { useSelector, useDispatch } from "react-redux";
import { addCompSet } from "../../Redux/Store/Slice/hotelCompset";
import NoData from "../../Pages/NoData";
import moment from "moment";
import "../../amplify/config";
import { MobileApi } from "../../amplify/api";
import type { HotelRate, OtaDemand, SuggDay } from "../../amplify/defs";

interface RootState {
  hotelDataId: {
    value: number;
  };
}
const YieldTable = () => {
  const dispatch = useDispatch();
  const { hotelDataId } = useSelector((state: RootState) => state);
  const currentHotelId = hotelDataId.value;
  const [stringArray, setStringArray] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState<SuggDay[]>([]);
  const [, setHotelData] = useState([]);
  const [acceptButton, setAcceptButton] = useState<string[]>([]);
  const [declineButton, setRejectButton] = useState<string[]>([]);
  const [counter, setCounter] = useState(0);
  const [defaultCompsetType, setDefaultCompsetType] = useState<
    "BB" | "RO" | "oneGuestBB" | "oneGuestRO"
  >();
  const [hotelName, setHotelName] = useState("");
  const [labelRow] = useState([
    "Day",
    "Date",
    "Suggested Base Rate",
    "Accept Rate",
    "Decline Rate",
    "Base Rate",
    "Left To Sell",
    "Market Demand",
  ]);

  const [media600, setMedia600] = useState(false);
  const [loader, setLoader] = useState(true);
  const [suggestedBaseRate, setSuggestedBaseRate] = useState<number[]>([]);
  const [otaTableData, setOtaTableData] = useState<HotelRate[]>([]);
  const [otaDemandData, setOtaDemandData] = useState<OtaDemand[]>([]);
  const date = new Date();

  // Date from today to next 30 days
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const currentDate = `${year}-${month}-${day}`;
  const twoYear = `${year + 2}-${month}-${day}`;

  let yieldData: SuggDay[];

  useEffect(() => {
    if (window.innerWidth >= 800) {
      setMedia600(true);
    } else {
      setMedia600(false);
    }
  });

  useEffect(() => {
    if (currentHotelId && counter > 0) {
      setLoader(true);
      allDataGet().finally(() => setLoader(false));
    }
  }, [counter, currentHotelId]);

  useEffect(() => {
    if (currentHotelId) {
      setLoader(true);
      allDataGet().finally(() => setLoader(false));
    }
  }, [currentHotelId]);

  const allDataGet = async () => {
    try {
      const filteredData = await MobileApi.getSuggDays(
        currentHotelId,
        currentDate,
        twoYear,
      );
      const filteredDataArray = Object.values(filteredData);
      setData(filteredDataArray as SuggDay[]);
      const currentHotelData = await MobileApi.getHotelConfig(currentHotelId);
      const compset =
        typeof currentHotelData.ota.compSet == "undefined"
          ? []
          : currentHotelData.ota.compSet;
      const compsetType = currentHotelData.ota.defaultCompsetType;
      const otaId = currentHotelData.ota.hotelId;

      const name = currentHotelData.info.name;
      setHotelName(name);

      setDefaultCompsetType(compsetType);

      setHotelData(currentHotelData);

      const allSuggestedDates = filteredData.map(
        (data: SuggDay) => data.hrDate,
      );

      const demandData = await MobileApi.getDemandFromOTATable(
        otaId,
        currentDate,
        twoYear,
      );

      const otaDemandDataFromTable: OtaDemand[] = [];
      allSuggestedDates.forEach((element: SuggDay) => {
        const momentDate = moment.utc(element + " 12:00").valueOf();

        otaDemandDataFromTable.push(demandData[momentDate]);
      });

      setOtaDemandData(otaDemandDataFromTable);

      const stringData = [];
      if (compset.length > 0) {
        const competitors = await MobileApi.getOtaHotelCompList(otaId);
        const otaData = await MobileApi.getRatesFromOTATable(
          otaId,
          currentDate,
          twoYear,
        );

        const otaDataFromTable: HotelRate[] = [];
        allSuggestedDates.forEach((element: SuggDay) => {
          const momentDate = moment.utc(element + " 12:00").valueOf();
          otaDataFromTable.push(otaData[momentDate]);
        });

        setOtaTableData(otaDataFromTable);

        for (let i = 0; i < compset.length; i++) {
          const competitor = competitors.find(
            (element) => element.id === compset[i],
          );
          if (typeof competitor !== "undefined") {
            stringData.push(competitor.name);
          }
        }
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      }
      if (!compset || compset.length === 0) {
        setLoader(false);
      }
      console.log(stringData);
      setStringArray(labelRow.concat(stringData));
      dispatch(addCompSet(labelRow.concat(stringData)));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    updateSuggestedBaseRate();
    updateAcceptChange();
    updateRejectChange();
  }, [data, media600, counter, currentPage]);

  registerAllModules();

  const paginate = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value);
  };

  // media state for responsiveness of the yieldSheet in the mobile screen and large screen too
  let OTADataFromOtaTable;
  let OTADemandDataFromOtaTable;
  if (media600) {
    const indexOfLastData = currentPage * 8;
    const indexOfFirstData = indexOfLastData - 8;
    yieldData = data.slice(indexOfFirstData, indexOfLastData);
    OTADataFromOtaTable = otaTableData.slice(indexOfFirstData, indexOfLastData);
    OTADemandDataFromOtaTable = otaDemandData.slice(
      indexOfFirstData,
      indexOfLastData,
    );
  } else {
    const indexOfLastData = currentPage * 2;
    const indexOfFirstData = indexOfLastData - 2;
    yieldData = data.slice(indexOfFirstData, indexOfLastData);
    OTADataFromOtaTable = otaTableData.slice(indexOfFirstData, indexOfLastData);
    OTADemandDataFromOtaTable = otaDemandData.slice(
      indexOfFirstData,
      indexOfLastData,
    );
  }

  // Helps us to get the days from date

  const datesData = yieldData.map((data: SuggDay) => data.hrDate);

  const getMonthName: string[] = [];
  datesData.forEach((element: string) => {
    getMonthName.push(moment(element).format("DD-MMM-YY"));
  });

  const getDayName: string[] = [];
  datesData.forEach((element: string) => {
    getDayName.push(moment(element).format("dddd"));
  });

  // Date that we want to display on the yield sheet (in mobile 2 days data to be displayed)

  const suggestedBaseRateData = yieldData.map(
    (data: SuggDay) => data.suggestedBaseRate,
  );
  const updateAcceptChange = () => {
    const updatedAcceptChange = datesData.map(
      () => "<button class='accept'>✔</button>",
    );
    setAcceptButton(updatedAcceptChange);
    return updatedAcceptChange;
  };

  const updateRejectChange = () => {
    const updatedRejectChange = datesData.map(
      () => "<button class='decline'>✖</button>",
    );
    setRejectButton(updatedRejectChange);
  };

  const updateSuggestedBaseRate = () => {
    const suggrates = suggestedBaseRateData.map((data: number) => data);
    setSuggestedBaseRate(suggrates);

    return suggrates;
  };

  const baseRateData = yieldData.map((data: SuggDay) => data.actualBaseRate);
  const leftToSellData = yieldData.map(
    (data: SuggDay) => data.availability.leftToSell,
  );
  const marketDemandData = OTADemandDataFromOtaTable.map((data: OtaDemand) => {
    if (data === undefined || data === null || isNaN(data.demand)) {
      return "-";
    }
    return Math.round(data.demand * 100) + "%";
  });

  const otaAllData = OTADataFromOtaTable.map((data: HotelRate) => data);
  console.log("ota data", otaAllData);

  // apply column logic for all columns (if neede to change the style of full table can be change by col class)

  const column = [];
  for (let i = 0; i <= datesData.length; i++) {
    const colData = {
      tableData: "Accept Rate",
      renderer: "html",
      class: "col",
    };
    column.push(colData);
  }

  // Data we want to display which we are getting from up

  const tableData: (string | number)[][] = [
    getDayName,
    getMonthName,
    suggestedBaseRate,
    acceptButton,
    declineButton,
    baseRateData,
    leftToSellData,
    marketDemandData,
  ];

  // showing "Sold out" at the place of undefined in compset section

  const startCompSet = stringArray.slice(8, stringArray.length);
  console.log(startCompSet);

  startCompSet.forEach((element) => {
    let compValueData: (string | number)[] = [];
    if (defaultCompsetType !== undefined) {
      compValueData = otaAllData.map(function (data: HotelRate) {
        console.log("data", data);
        const compsetV = data[element]?.[defaultCompsetType];
        let compsetMessage =
          data[element]?.[
            (defaultCompsetType + "Message") as
              | "BBMessage"
              | "ROMessage"
              | "oneGuestBBMessage"
              | "oneGuestROMessage"
          ];
        if (compsetMessage === undefined) {
          compsetMessage = "not.retrieved";
        }
        let compsetMessageDisplay = "-";

        switch (compsetMessage) {
          case "general.missing":
            compsetMessageDisplay = "-";
            break;
          case "rates.soldout":
            compsetMessageDisplay = "Sold Out";
            break;
          case "rates.nobar":
            compsetMessageDisplay = "No BAR";
            break;
          case "rates.meal.missing.1":
            compsetMessageDisplay = "No BB";
            break;
          case "rates.meal.missing.5":
            compsetMessageDisplay = "No RO";
            break;
          case "rates.restrictionlos2":
            compsetMessageDisplay = "LOS2";
            break;
          case "rates.restrictionlos3":
            compsetMessageDisplay = "LOS3";
            break;
          case "rates.no_person.1":
            compsetMessageDisplay = "No 1 pax";
            break;
          case "rates.no_person.2":
            compsetMessageDisplay = "No 2 pax";
            break;
          case "rates.onlythirdparty":
            compsetMessageDisplay = "3rd party";
            break;
          case "not.retrieved":
            compsetMessageDisplay = "Sold Out*";
            break;
          default:
            compsetMessageDisplay = "Err";
            console.log("compsetMessage not handled", compsetMessage);
            break;
        }
        const t =
          typeof compsetV != "undefined"
            ? compsetV == 0
              ? compsetMessageDisplay
              : compsetV
            : "-";
        return t;
      });
    }

    tableData.push(compValueData);
  });

  const modifiedArray = tableData.map((innerArray, index) => {
    const firstElement = stringArray[index];
    if (Array.isArray(innerArray)) {
      return [firstElement, ...innerArray];
    }
    return [firstElement];
  });

  //TD is used line 568
  // eslint-disable-next-line
  const acceptChangesOfSuggestedRate = async (coords: any, TD: any) => {
    const hotelId = currentHotelId;
    const start = datesData[coords.col - 1];
    const end = datesData[coords.col - 1];
    const suggBaseRate = suggestedBaseRate[coords.col - 1];
    const baseRate = baseRateData[coords.col - 1];
    const col = coords.col - 1;
    const updatedDatesData = [...acceptButton];
    updatedDatesData[col] = "<button class='accept'>Processing...</button>";
    setAcceptButton(updatedDatesData);

    try {
      // First, accept the suggested rate
      await toast.promise(
        MobileApi.acceptSuggestedRate({
          hotelId: hotelId,
          start: start,
          end: end,
          newBaseRate: suggBaseRate,
          previousBaseRate: baseRate,
        }),
        {
          pending: "Accepting rate change...",
          success: "Rate change accepted",
          error: "Failed to accept rate change",
        },
      );

      // Add a small delay before publishing
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Then publish the rates
      await toast.promise(
        MobileApi.publishrates({
          hotelId: hotelId,
          start: start,
          end: end,
        }),
        {
          pending: "Publishing rate change...",
          success: "Rate change published",
          error: "Failed to publish rate change",
        },
      );

      // Add a small delay before fetching updated data
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Then fetch the updated data
      await allDataGet();

      // Reset the button state
      const resetButtonData = [...acceptButton];
      resetButtonData[col] = "<button class='accept'>✔</button>";
      setAcceptButton(resetButtonData);
    } catch (error) {
      console.error("Error accepting rate:", error);
      // Reset the button state on error
      const resetButtonData = [...acceptButton];
      resetButtonData[col] = "<button class='accept'>✔</button>";
      setAcceptButton(resetButtonData);
    }
  };

  const denyChangesOfSuggestedRate = async (coords: { col: number }) => {
    const hotelId = currentHotelId;
    const start = datesData[coords.col - 1];
    const end = datesData[coords.col - 1];
    const col = coords.col - 1;
    const updatedDatesData = [...declineButton];
    updatedDatesData[col] = "<button class='decline'>Processing...</button>";
    setRejectButton(updatedDatesData);

    try {
      // First, wait for the decline API call to complete
      await MobileApi.removeSuggRate({
        end,
        hotelId,
        start,
        suggestedRate: 0,
      });

      // Add a small delay to ensure the server has processed the decline
      await new Promise((resolve) => setTimeout(resolve, 500));

      // Then fetch the updated data
      await allDataGet();

      // Show success message
      toast.success("Declined suggestion");

      // Reset the button state
      const resetButtonData = [...declineButton];
      resetButtonData[col] = "<button class='decline'>✖</button>";
      setRejectButton(resetButtonData);
    } catch (error) {
      console.error("Error declining rate:", error);
      toast.error("Error while declining suggestion");
      // Reset the button state on error
      const resetButtonData = [...declineButton];
      resetButtonData[col] = "<button class='decline'>✖</button>";
      setRejectButton(resetButtonData);
    }
  };

  // conditions to be applied on particular cell in handsone table

  const cell1 = [];

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 1,
      col: i,
      className: "cell1",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 5,
      col: i,
      className: "cell1",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 6,
      col: i,
      className: "leftToSell",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    const cell = {
      row: 7,
      col: i,
      className: "marketDemand",
    };

    cell1.push(cell);
  }

  for (let i = 0; i <= datesData.length; i++) {
    for (let j = 0; j <= 1; j++) {
      const cell = {
        row: j,
        col: i,
        editor: false,
      };

      cell1.push(cell);
    }
  }

  for (let i = 0; i <= datesData.length; i++) {
    for (let j = 3; j <= stringArray.length; j++) {
      const cell = {
        row: j,
        col: i,
        editor: false,
      };

      cell1.push(cell);
    }
  }

  const cell = {
    row: 2,
    col: 0,
    editor: false,
  };
  cell1.push(cell);

  const comSetFromMD = stringArray.slice(8, stringArray.length);

  for (let i = 0; i <= datesData.length; i++) {
    for (let y = 8; y <= comSetFromMD.length + 8; y++) {
      const compSet = {
        row: y,
        col: i,
        className: "compSet",
      };
      cell1.push(compSet);
    }
  }

  const changeValue = async (
    changes: [number, string, string, string][] | null,
  ) => {
    if (!changes || changes.length === 0) return;

    const col = changes[0][1];
    const indCol = parseInt(col) - 1;

    const end = datesData[indCol];
    const hotelId = currentHotelId;
    const start = datesData[indCol];
    const suggestedRate = parseInt(changes[0][3]);

    console.log("suggestedRate??????????????????????????", suggestedRate);

    const updatedSuggRateData = [...suggestedBaseRate];
    updatedSuggRateData.splice(indCol, 1, suggestedRate);

    setSuggestedBaseRate(updatedSuggRateData);

    if (suggestedRate === baseRateData[indCol]) {
      await MobileApi.removeSuggRate({
        end,
        hotelId,
        start,
        suggestedRate: 0,
      });
    } else {
      await MobileApi.suggestRate({
        end,
        hotelId,
        start,
        suggestedRate,
      });
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    setCounter((prevCounter) => prevCounter + 1);
  };

  return (
    <>
      <h3 style={{ textAlign: "center" }}>{hotelName}</h3>
      {loader ? (
        <div className="spinner">
          <CircularProgress color="secondary" />
        </div>
      ) : data.length === 0 ? (
        <NoData />
      ) : (
        <div style={{ marginTop: "3vh" }}>
          <ToastContainer />

          <HotTable
            settings={{
              afterChange: (changes) => {
                changeValue(changes as [number, string, string, string][]);
              },
              afterOnCellMouseDown: (Event, coords, TD) => {
                if (coords.row === 3 && coords.col != 0) {
                  acceptChangesOfSuggestedRate(coords, TD);
                } else if (coords.row === 4 && coords.col != 0) {
                  denyChangesOfSuggestedRate(coords);
                }
              },
              disableVisualSelection: true,
              wordWrap: true,
            }}
            cell={cell1}
            columns={column}
            data={modifiedArray}
            rowHeaders={false}
            licenseKey="non-commercial-and-evaluation"
            stretchH="all"
            rowHeights={40}
            fixedColumnsStart={1}
            colWidths={media600 ? [100] : [60]}
          ></HotTable>
          <br />
          <div className="pegination">
            {media600
              ? data.length > 8 && (
                  <Pagination
                    color="standard"
                    shape="rounded"
                    defaultPage={1}
                    count={Math.ceil(data.length / 8)}
                    page={currentPage}
                    onChange={paginate}
                  />
                )
              : data.length > 2 && (
                  <Pagination
                    color="standard"
                    shape="rounded"
                    defaultPage={1}
                    count={Math.ceil(data.length / 2)}
                    page={currentPage}
                    onChange={paginate}
                  />
                )}

            <br />
          </div>
        </div>
      )}
    </>
  );
};

export default YieldTable;
